/** HTTP Request methods */
export enum Method {
  GET = "GET",
  POST = "POST",
  PUT = "PUT",
  DELETE = "DELETE",
}

/** HTTP Request options */
export interface RequestConfig {
  method: Method
  endpoint: string
  headers?: HeadersInit
  parameters?: any
  formData?: FormData
}

/**
 * Execute HTTP Request
 * @param   {string} baseURL API base URL for sending the request
 * @param   {RequestConfig} config Generic config for the HTTP request method, body, headers
 * @return  {Promise<Response>} HTTP response promise
 */
export function execRequest(
  baseURL: string,
  config: RequestConfig
): Promise<Response> {
  return fetch(`${baseURL}${config.endpoint}`, {
    method: config.method,
    headers: config.headers,
    body:
      config.formData != null
        ? config.formData
        : JSON.stringify(config.parameters),
  })
}
