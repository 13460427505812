import { combineReducers } from "redux"

export * from "./articlesSlice"
export * from "./formsSlice"

import articlesSlice, { ArticlesState } from "./articlesSlice"
import formsSlice, { FormsState } from "./formsSlice"

export interface RootState {
  articles: ArticlesState
  forms: FormsState
}

export default combineReducers({ articles: articlesSlice, forms: formsSlice })
