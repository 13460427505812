import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit"

import { execRequest, Method } from "../utils/client"
import { Article } from "./models"

const API_URL = process.env.MARKETING_API_URL || ""

export interface ArticlesState {
  articles: Article[]

  // Loading/Error State
  error: string | null
  loading: boolean
}

const initialState: ArticlesState = {
  articles: [],

  // Loading/Error State
  error: null,
  loading: false,
}

export const getArticles = createAsyncThunk(
  "articles/getArticles",
  async (_, { rejectWithValue }) => {
    try {
      const response = await execRequest(API_URL, {
        method: Method.GET,
        headers: { "Content-Type": "application/json" },
        endpoint: "/learning/articles?user_id=53",
      })

      return (await response.json()).data as Article[]
    } catch (err) {
      return rejectWithValue(await err.response.data)
    }
  }
)

const articlesSlice = createSlice({
  name: "articles",
  initialState,
  reducers: {},
  extraReducers: builder => {
    /** Get Blog Posts */
    builder.addCase(getArticles.pending, state => {
      state.loading = true
    })
    builder.addCase(
      getArticles.fulfilled,
      (state, action: PayloadAction<Article[]>) => {
        state.loading = false
        state.articles = action.payload
      }
    )
    builder.addCase(getArticles.rejected, (state, action) => {
      state.loading = false
      state.error = action.error.message ?? "Unable to Fetch Articles"
    })
  },
})

export default articlesSlice.reducer
