import React from "react"

import { Provider } from "react-redux"
import { createStore, applyMiddleware } from "redux"
import reduxThunk from "redux-thunk"

import rootReducer from "./index"

const middleware = applyMiddleware(reduxThunk)

const store: any = middleware(createStore)(rootReducer)

const reducerWrapper = ({ element }: any) => (
  <Provider store={store}>{element}</Provider>
)

export default reducerWrapper
