import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

import { execRequest, Method } from "../utils/client"
import { DemoRequest } from "./models"

const API_URL = process.env.MARKETING_API_URL || ""
const BACKEND_API_URL = process.env.BACKEND_API_URL || ""

export interface FormsState {
  // Loading/Error State
  error: string | null
  loading: boolean
}

const initialState: FormsState = {
  // Loading/Error State
  error: null,
  loading: false,
}

export const submitDemoRequest = createAsyncThunk(
  "forms/submitDemoRequest",
  async (request: DemoRequest, { rejectWithValue }) => {
    try {
      const response = await execRequest(BACKEND_API_URL, {
        method: Method.POST,
        headers: { "Content-Type": "application/json" },
        endpoint: "/demoForm.create",
        parameters: {
          json: { ...request },
        },
      })

      return response.status === 201
    } catch (err) {
      return rejectWithValue(await err.response.data)
    }
  }
)

export const submitEmailAddress = createAsyncThunk(
  "forms/submitEmailAddress",
  async (address: string, { rejectWithValue }) => {
    try {
      const response = await execRequest(API_URL, {
        method: Method.POST,
        headers: { "Content-Type": "application/json" },
        endpoint: "/marketing/groups/1/emails",
        parameters: {
          address,
        },
      })

      return response.status === 201
    } catch (err) {
      return rejectWithValue(await err.response.data)
    }
  }
)

const formsSlice = createSlice({
  name: "forms",
  initialState,
  reducers: {},
  extraReducers: builder => {
    // Submit Email
    builder.addCase(submitEmailAddress.pending, state => {
      state.loading = true
    })
    builder.addCase(submitEmailAddress.fulfilled, state => {
      state.loading = false
    })
    builder.addCase(submitEmailAddress.rejected, (state, action) => {
      state.loading = false
      state.error = action.error.message ?? "Unable to Fetch Articles"
    })
    // Demo Request
    builder.addCase(submitDemoRequest.pending, state => {
      state.loading = true
    })
    builder.addCase(submitDemoRequest.fulfilled, state => {
      state.loading = false
    })
    builder.addCase(submitDemoRequest.rejected, (state, action) => {
      state.loading = false
      state.error = action.error.message ?? "Unable to Fetch Articles"
    })
  },
})

export default formsSlice.reducer
